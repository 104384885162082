var render = function render(){
  var _vm$global$profile;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', {
    staticClass: "transparent",
    class: {
      dark: _vm.global.dark
    }
  }, [_vm.global.notify ? _c('snackbar', _vm._b({
    attrs: {
      "value": !!_vm.global.notify
    },
    on: {
      "input": function ($event) {
        _vm.global.notify = null;
      }
    }
  }, 'snackbar', _vm.global.notify.props, false), [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.global.notify.msg)
    }
  })]) : _vm._e(), _vm.showSignIn ? _c('sign-in-dialog', {
    model: {
      value: _vm.showSignIn,
      callback: function ($$v) {
        _vm.showSignIn = $$v;
      },
      expression: "showSignIn"
    }
  }) : _vm._e(), _vm.sections.header ? _c('v-navigation-drawer', {
    directives: [{
      name: "no-scroll",
      rawName: "v-no-scroll"
    }],
    staticStyle: {
      "height": "100%"
    },
    attrs: {
      "app": "",
      "right": "",
      "disable-resize-watcher": "",
      "mobile-breakpoint": "999999",
      "color": "grey lighten-5"
    },
    model: {
      value: _vm.showNavMenu,
      callback: function ($$v) {
        _vm.showNavMenu = $$v;
      },
      expression: "showNavMenu"
    }
  }, [_c('v-list', {
    staticClass: "py-0"
  }, [_c('v-list-item', {
    staticClass: "px-4 py-2 align-center",
    attrs: {
      "exact": "",
      "to": {
        name: 'start'
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "ec-dark-blue"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiHome)
    }
  }), _c('v-spacer'), _c('img', {
    staticClass: "ml-2 d-block",
    attrs: {
      "width": "80",
      "src": "/ellacard-logo.png",
      "alt": "Ellacard",
      "title": "Ellacard"
    }
  })], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "to": {
        name: 'account'
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "ec-dark-blue"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.isSignedIn ? _vm.mdiAccountCheck : _vm.mdiAccount)
    }
  }), _c('v-list-item-content', {
    staticClass: "ml-3"
  }, [_c('v-list-item-title', {
    staticClass: "ec-dark-blue--text text-body-2"
  }, [_vm._v("Account")]), _c('v-list-item-subtitle', {
    staticClass: "caption",
    domProps: {
      "innerHTML": _vm._s(_vm.isSignedIn ? _vm.global.profile.email : 'Not signed in')
    }
  })], 1)], 1), _c('v-list-item', {
    on: {
      "click": function ($event) {
        _vm.showPending = _vm.showPending == 'side' ? null : 'side';
      }
    }
  }, [_c('div', {
    staticClass: "flex-shrink-0"
  }, [_vm.loadPendingOrdersStatus.pending ? _c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "color": "ec-dark-blue",
      "size": "24",
      "width": "2"
    }
  }) : _c('v-icon', {
    attrs: {
      "color": "ec-dark-blue"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiCart)
    }
  })], 1), _c('v-list-item-title', {
    staticClass: "ml-3 text-body-2 ec-dark-blue--text"
  }, [_vm._v(" Saved projects ")]), _c('v-list-item-action', [_c('v-icon', {
    domProps: {
      "innerHTML": _vm._s(_vm.showPending == 'side' ? _vm.mdiMenuUp : _vm.mdiMenuDown)
    }
  })], 1)], 1), _c('v-expand-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showPending == 'side' && !_vm.loadPendingOrdersStatus.pending,
      expression: "(showPending == 'side') && !loadPendingOrdersStatus.pending"
    }],
    staticClass: "overflow-auto",
    staticStyle: {
      "max-height": "372px"
    }
  }, [_vm._l(_vm.pendingOrders, function (_ref) {
    var orderId = _ref[0],
      order = _ref[1];
    return _c('cart-list-item', {
      key: 'side-' + orderId,
      attrs: {
        "order-id": orderId,
        "order": order,
        "to": {
          name: 'project',
          params: {
            orderId,
            routeOnClose: _vm.$routeAssign({})
          }
        }
      }
    });
  }), !_vm.pendingOrders.length ? _c('div', {
    staticClass: "pl-13 py-3 text--label-active font-italic"
  }, [_vm._v("No saved projects")]) : _vm._e()], 2)]), _c('v-divider'), _vm._l(_vm.NAV_MENUS, function (component, i) {
    return _c(component, {
      key: 'list-' + i,
      ref: "menu",
      refInFor: true,
      tag: "component",
      attrs: {
        "disabled": _vm.menu != -1 && _vm.menu != i
      },
      on: {
        "spotlight": function (v) {
          return _vm.menu = v ? i : _vm.menu == -1 || _vm.menu == i ? -1 : _vm.menu;
        }
      }
    });
  }), _c('v-divider'), _vm._l([['catalog-categories', 'Categories', _vm.mdiExpandAll], ['catalog', 'Cards', _vm.mdiCards], ['catalog-video', 'Videos', _vm.mdiVideoVintage], ['featured', 'Featured cards', _vm.mdiTrophy], ['batch-order', 'Batch order', _vm.mdiCalendarMultiselect], ['design', 'Design program', _vm.mdiImageSizeSelectLarge], ['pricing', 'Pricing', _vm.mdiCurrencyUsd], ['faq', 'Faq', _vm.mdiFrequentlyAskedQuestions], ['blog', 'Blog', _vm.mdiFormatFloatLeft], ['site-map', 'More', _vm.mdiDotsHorizontal]], function (_ref2) {
    var name = _ref2[0],
      title = _ref2[1],
      icon = _ref2[2];
    return _c('v-list-item', {
      key: name,
      staticClass: "ec-dark-blue--text",
      attrs: {
        "to": {
          name
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "ec-dark-blue"
      },
      domProps: {
        "innerHTML": _vm._s(icon)
      }
    }), _c('v-list-item-title', {
      staticClass: "ml-3 text-body-2",
      domProps: {
        "innerHTML": _vm._s(title)
      }
    })], 1);
  }), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "href": "mailto:contact@ellacard.com"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "ec-dark-blue"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiEmail)
    }
  }), _c('v-list-item-content', {
    staticClass: "ml-3"
  }, [_c('v-list-item-title', {
    staticClass: "ec-dark-blue--text text-body-2"
  }, [_vm._v("Contact us")]), _c('v-list-item-subtitle', [_vm._v("contact@ellacard.com")])], 1)], 1), _c('v-divider')], 2), (_vm$global$profile = _vm.global.profile) !== null && _vm$global$profile !== void 0 && _vm$global$profile.admin ? [_c('div', {
    staticClass: "overline pt-2 px-4 mb-n2"
  }, [_vm._v("Admin")]), _c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.$router.options.routes.find(function (x) {
    return x.name == 'admin';
  }).children.map(function (x) {
    return x.name.substr(6);
  }), function (name) {
    return _c('v-list-item', {
      key: name,
      attrs: {
        "to": {
          name: 'admin-' + name
        }
      }
    }, [_c('v-list-item-title', {
      staticClass: "font-italic",
      domProps: {
        "innerHTML": _vm._s(name)
      }
    })], 1);
  }), 1)] : _vm._e()], 2) : _vm._e(), _c('v-main', {
    staticClass: "relative"
  }, [_vm.sections.header ? _c('div', {
    staticClass: "section-header relative",
    class: _vm.$router.currentRouteMeta.$class
  }, [_c('div', {
    staticClass: "nav-btns pa-3 d-flex justify-end"
  }, [_c('router-link', {
    staticClass: "mb-n3",
    attrs: {
      "to": {
        name: 'start'
      }
    }
  }, [_c('img', {
    staticClass: "d-block",
    attrs: {
      "height": "56",
      "src": "/ellacard-logo.png",
      "alt": "Ellacard",
      "title": "Ellacard"
    }
  })]), _c('v-spacer'), _c('v-btn', {
    staticClass: "px-2 px-sm-3 hidden-xs-only",
    staticStyle: {
      "pointer-events": "auto",
      "text-transform": "none"
    },
    attrs: {
      "active-class": "before-opacity-0",
      "text": "",
      "color": "ec-dark-blue",
      "aria-label": "Home",
      "to": {
        name: 'start'
      }
    }
  }, [_c('v-icon', {
    domProps: {
      "innerHTML": _vm._s(_vm.mdiHome)
    }
  }), _c('span', {
    staticClass: "btn-tooltip bottom"
  }, [_vm._v("Home")])], 1), _c('v-btn', {
    staticClass: "px-2 px-sm-3",
    staticStyle: {
      "pointer-events": "auto",
      "text-transform": "none"
    },
    attrs: {
      "active-class": "before-opacity-0",
      "text": "",
      "color": "ec-dark-blue",
      "aria-label": "All cards and invitations",
      "to": {
        name: 'catalog'
      }
    }
  }, [_c('v-icon', {
    domProps: {
      "innerHTML": _vm._s(_vm.mdiCards)
    }
  }), _c('span', {
    staticClass: "btn-tooltip bottom"
  }, [_vm._v("All cards and invitations")])], 1), _vm.isSignedIn ? _c('v-menu', {
    attrs: {
      "eager": "",
      "bottom": "",
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var _vm$$route$matched$;
        var on = _ref3.on,
          attrs = _ref3.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "px-2 pl-sm-3",
          attrs: {
            "text": "",
            "aria-label": "Account",
            "color": "ec-dark-blue",
            "input-value": ((_vm$$route$matched$ = _vm.$route.matched[0]) === null || _vm$$route$matched$ === void 0 ? void 0 : _vm$$route$matched$.name) == 'account'
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          domProps: {
            "innerHTML": _vm._s(_vm.mdiAccountCheck)
          }
        }), _c('div', {
          staticClass: "mx-1 hidden-xs-only",
          staticStyle: {
            "max-width": "30vw",
            "overflow": "hidden",
            "text-transform": "none"
          }
        }, [_vm._v(" " + _vm._s(_vm.global.profile.email) + " ")]), _c('v-icon', {
          staticClass: "mx-n1",
          domProps: {
            "innerHTML": _vm._s(_vm.mdiMenuDown)
          }
        }), _c('span', {
          staticClass: "btn-tooltip bottom"
        }, [_vm._v("Account")])], 1)];
      }
    }], null, false, 3286962632)
  }, [_c('v-list', {
    staticClass: "py-0 grey lighten-5"
  }, [_c('v-list-item', {
    attrs: {
      "exact": "",
      "to": {
        name: 'account'
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "ec-dark-blue"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiAccount)
    }
  }), _c('v-list-item-content', {
    staticClass: "ml-3"
  }, [_c('v-list-item-title', {
    staticClass: "ec-dark-blue--text text-body-2"
  }, [_vm._v("Account")]), _vm.$vuetify.breakpoint.xsOnly ? _c('v-list-item-subtitle', {
    domProps: {
      "textContent": _vm._s(_vm.global.profile.email)
    }
  }) : _vm._e()], 1)], 1), _vm._l([['Cards you ordered', 'account-orders'], ['Cards you signed', 'account-signed'], ['Cards you received', 'account-received'], ['Ellacard contacts', 'account-contacts']], function (_ref4) {
    var title = _ref4[0],
      name = _ref4[1];
    return _c('v-list-item', {
      key: name,
      staticClass: "pl-13",
      attrs: {
        "to": {
          name
        }
      }
    }, [_c('v-list-item-title', {
      staticClass: "ec-dark-blue--text text-body-2",
      domProps: {
        "innerHTML": _vm._s(title)
      }
    })], 1);
  }), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "to": {
        name: 'account-design'
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "ec-dark-blue"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiImageSizeSelectLarge)
    }
  }), _c('v-list-item-title', {
    staticClass: "ml-3 ec-dark-blue--text text-body-2"
  }, [_vm._v("Design portal")])], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "to": {
        name: 'account-org'
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "ec-dark-blue"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiDomain)
    }
  }), _c('v-list-item-title', {
    staticClass: "ml-3 ec-dark-blue--text text-body-2"
  }, [_vm._v("Business portal")])], 1), _c('v-divider'), _c('v-list-item', {
    on: {
      "click": function ($event) {
        _vm.global.jwt = null;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "ec-dark-blue"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.mdiLogout)
    }
  }), _c('v-list-item-title', {
    staticClass: "ml-3 ec-dark-blue--text text-body-2"
  }, [_vm._v("Sign out")])], 1)], 2)], 1) : _c('v-btn', {
    attrs: {
      "text": "",
      "aria-label": "Sign in",
      "color": "ec-dark-blue"
    },
    on: {
      "click": function ($event) {
        _vm.showSignIn = true;
      }
    }
  }, [_c('v-icon', {
    domProps: {
      "innerHTML": _vm._s(_vm.mdiAccount)
    }
  }), _c('div', {
    staticClass: "ml-1 hidden-xs-only",
    staticStyle: {
      "text-transform": "none"
    }
  }, [_vm._v("Sign in")]), _c('span', {
    staticClass: "btn-tooltip bottom"
  }, [_vm._v("Sign in")])], 1), _c('v-menu', {
    directives: [{
      name: "no-scroll",
      rawName: "v-no-scroll"
    }],
    attrs: {
      "bottom": "",
      "offset-y": "",
      "close-on-content-click": "",
      "min-width": "300",
      "content-class": "grey lighten-5",
      "value": _vm.showPending == 'top'
    },
    on: {
      "input": function (x) {
        return _vm.showPending = x ? 'top' : null;
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref5) {
        var on = _ref5.on,
          attrs = _ref5.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "px-2 pl-sm-3",
          attrs: {
            "text": "",
            "aria-label": "Saved projects",
            "color": "ec-dark-blue",
            "loading": _vm.showPending == 'top' && _vm.loadPendingOrdersStatus.pending
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          domProps: {
            "innerHTML": _vm._s(_vm.mdiCart)
          }
        }), _c('v-icon', {
          staticClass: "mx-n1",
          domProps: {
            "innerHTML": _vm._s(_vm.mdiMenuDown)
          }
        }), _c('span', {
          staticClass: "btn-tooltip bottom"
        }, [_vm._v("Saved projects")])], 1)];
      }
    }], null, false, 1746616865)
  }, [_c('v-list', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loadPendingOrdersStatus.pending,
      expression: "!loadPendingOrdersStatus.pending"
    }],
    staticClass: "py-0 grey lighten-5"
  }, [_c('div', {
    staticClass: "text--label-active pa-2"
  }, [_vm._v("Saved projects")]), _vm._l(_vm.pendingOrders, function (_ref6) {
    var orderId = _ref6[0],
      order = _ref6[1];
    return _c('cart-list-item', {
      key: orderId,
      staticClass: "pl-0",
      attrs: {
        "order-id": orderId,
        "order": order,
        "to": {
          name: 'project',
          params: {
            orderId,
            routeOnClose: _vm.$routeAssign({})
          }
        }
      }
    });
  }), !_vm.pendingOrders.length ? _c('div', {
    staticClass: "pa-3 text--label-active font-italic"
  }, [_vm._v("No saved projects")]) : _vm._e()], 2)], 1), _c('v-btn', {
    staticClass: "px-2 px-sm-3",
    attrs: {
      "text": "",
      "aria-label": "Navigation menu",
      "color": "ec-dark-blue",
      "input-value": _vm.showNavMenu
    },
    on: {
      "click": function ($event) {
        _vm.showNavMenu = !_vm.showNavMenu;
      }
    }
  }, [_c('v-icon', {
    domProps: {
      "innerHTML": _vm._s(_vm.mdiMenu)
    }
  })], 1)], 1), _c('div', {
    staticClass: "nav-menu-btns hidden-xs-only mx-auto px-3 pb-3 d-sm-flex flex-wrap justify-center"
  }, _vm._l(_vm.NAV_MENUS, function (component, i) {
    return _c(component, {
      key: 'head-' + i,
      tag: "component",
      attrs: {
        "header": "",
        "disabled": !_vm.showNavMenu && _vm.menu != -1 && _vm.menu != i
      },
      on: {
        "spotlight": function (v) {
          return _vm.menu = v ? i : _vm.menu == -1 || _vm.menu == i ? -1 : _vm.menu;
        }
      }
    });
  }), 1)]) : _vm._e(), _c('router-view')], 1), _vm.sections.footer ? _c('v-footer', {
    attrs: {
      "dark": "",
      "tile": ""
    }
  }, [_c('div', {
    staticClass: "flex-grow-1 overline"
  }, [_c('div', {
    staticClass: "my-2 float-right"
  }, [_vm._v("© 2024")]), _c('div', {
    staticClass: "my-2 white--text"
  }, [_vm._v("Ellacard, LLC")]), _c('div', {
    staticClass: "mx-n2 d-flex flex-wrap"
  }, [_c('router-link', {
    staticClass: "ma-2 white--text",
    attrs: {
      "to": "/start"
    }
  }, [_vm._v("Home")]), _c('router-link', {
    staticClass: "ma-2 white--text",
    attrs: {
      "to": "/faq"
    }
  }, [_vm._v("FAQ")]), _c('router-link', {
    staticClass: "ma-2 white--text",
    attrs: {
      "to": "/about"
    }
  }, [_vm._v("About")]), _c('router-link', {
    staticClass: "ma-2 white--text",
    attrs: {
      "to": "/privacy-policy"
    }
  }, [_vm._v("Privacy Policy")]), _c('router-link', {
    staticClass: "ma-2 white--text",
    attrs: {
      "to": "/terms-and-conditions"
    }
  }, [_vm._v("Terms and Conditions")]), _c('router-link', {
    staticClass: "ma-2 white--text",
    attrs: {
      "to": "/site-map"
    }
  }, [_vm._v("Site map")]), _c('a', {
    staticClass: "ma-2 white--text",
    attrs: {
      "href": "mailto:contact@ellacard.com"
    }
  }, [_vm._v("contact@ellacard.com")])], 1), _c('div', {
    staticClass: "mx-n2 d-flex flex-wrap"
  }, _vm._l(_vm.SOCIALS, function (_ref7) {
    var icon = _ref7[0],
      title = _ref7[1],
      url = _ref7[2];
    return _c('v-btn', {
      key: title,
      staticClass: "ma-2",
      attrs: {
        "icon": "",
        "target": "_blank",
        "aria-label": title,
        "href": url
      }
    }, [_c('v-icon', {
      domProps: {
        "innerHTML": _vm._s(icon)
      }
    })], 1);
  }), 1)])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
import { LOCALE, TZ } from '@/utils/intl.js';
import { getTimezoneName } from '@/utils/time.js';


function asDate(v, opts, truncateMinutes, locale=LOCALE) {
  if (!v)
    return null;
  const d = new Date(v);
  if (truncateMinutes && !parseInt(d.toLocaleString(locale, { hour12: false, minute: '2-digit', timeZone: opts.timeZone || TZ })))
    return d.toLocaleString(locale, { ...opts, minute: undefined });
  return d.toLocaleString(locale, opts);
}

function getOrdinal(n) {
  if (n > 3 && n < 21)
    return 'th';
  switch (n % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

const FORMATTERS = {
  name: [
    [ 'name' ],
    e => e.name
  ],
  from: [
    [ 'from' ],
    e => asDate(e.from, { weekday: 'long', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit', timeZone: e.tz || TZ }, true)
  ],
  from_weekday: [
    [ 'from' ],
    e => asDate(e.from, { weekday: 'long', timeZone: e.tz || TZ })
  ],
  from_weekday_abbr: [
    [ 'from' ],
    e => asDate(e.from, { weekday: 'short', timeZone: e.tz || TZ })
  ],
  from_month: [
    [ 'from' ],
    e => asDate(e.from, { month: 'long', timeZone: e.tz || TZ })
  ],
  from_month_abbr: [
    [ 'from' ],
    e => asDate(e.from, { month: 'short', timeZone: e.tz || TZ })
  ],
  from_day: [
    [ 'from' ],
    e => asDate(e.from, { day: 'numeric', timeZone: e.tz || TZ })
  ],
  from_day_en_ordinal: [
    [ 'from' ],
    e => {
      const n = asDate(e.from, { day: 'numeric', timeZone: e.tz || TZ });
      return n + '<sup>' + getOrdinal(parseInt(n)) + '</sup>';
    }
  ],
  from_date: [
    [ 'from' ],
    e => asDate(e.from, { month: 'long', day: 'numeric', timeZone: e.tz || TZ })
  ],
  from_date_en_ordinal: [
    [ 'from' ],
    e => asDate(e.from, { month: 'long', day: 'numeric', timeZone: e.tz || TZ }, false, 'en-US') + '<sup>' + getOrdinal(parseInt(asDate(e.from, { day: 'numeric', timeZone: e.tz || TZ }))) + '</sup>'
  ],
  from_date_year: [
    [ 'from' ],
    e => asDate(e.from, { dateStyle: 'long', timeZone: e.tz || TZ })
  ],
  from_date_numeric: [
    [ 'from' ],
    e => asDate(e.from, { month: 'numeric', day: 'numeric', timeZone: e.tz || TZ })
  ],
  from_date_numeric_year: [
    [ 'from' ],
    e => asDate(e.from, { dateStyle: 'short', timeZone: e.tz || TZ })
  ],
  from_time: [
    [ 'from' ],
    e => asDate(e.from, { timeStyle: 'short', timeZone: e.tz || TZ })
  ],
  from_time_truncate_minutes: [
    [ 'from' ],
    e => asDate(e.from, { hour: 'numeric', minute: '2-digit', timeZone: e.tz || TZ }, true)
  ],

  tz_long: [
    [ 'from' ],
    e => e.from && getTimezoneName(e.from, 'long', e.tz || TZ, LOCALE)
  ],
  tz_short: [
    [ 'from' ],
    e => e.from && getTimezoneName(e.from, 'short', e.tz || TZ, LOCALE)
  ],
  tz_long_generic: [
    [ 'from' ],
    e => e.from && getTimezoneName(e.from, 'longGeneric', e.tz || TZ, LOCALE)
  ],
  tz_short_generic: [
    [ 'from' ],
    e => e.from && getTimezoneName(e.from, 'shortGeneric', e.tz || TZ, LOCALE)
  ],

  datetime_range: [
    [ 'from', 'to' ],
    e => {
      if (!e.from)
        return null;
      if (!e.to)
        return asDate(e.from, { weekday: 'long', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit', timeZone: e.tz || TZ }, true);
      const dateFmt = { weekday: 'long', month: 'long', day: 'numeric', timeZone: e.tz || TZ };
      const from = new Date(e.from);
      const to = new Date(e.to);
      if (to - from > 1000 * 60 * 60 * 24)
        // If more than 24 hours apart, then just use the dates
        return asDate(e.from, dateFmt) + '–' + asDate(e.to, dateFmt);
      const fromTime = asDate(e.from, { hour: 'numeric', minute: '2-digit', timeZone: e.tz || TZ }, true);
      const toTime = asDate(e.to, { hour: 'numeric', minute: '2-digit', timeZone: e.tz || TZ }, true);
      if (!/[AP]M$/i.test(fromTime))
        // Our locale does not use AM / PM
        return asDate(e.from, dateFmt) + ', ' + fromTime + ' – ' + toTime;
      if (fromTime.slice(-2) == toTime.slice(-2))
        // These times share the same suffix
        return asDate(e.from, dateFmt) + ', ' + fromTime.replace(/\s+[AP]M$/, '') + ' – ' + toTime;
      return asDate(e.from, dateFmt) + ', ' + fromTime + ' – ' + toTime;
    }
  ],

  time_range: [
    [ 'from', 'to' ],
    e => {
      if (!e.from)
        return null;
      if (!e.to)
        return asDate(e.from, { hour: 'numeric', minute: '2-digit', timeZone: e.tz || TZ }, true);
      const fromTime = asDate(e.from, { hour: 'numeric', minute: '2-digit', timeZone: e.tz || TZ }, true);
      const toTime = asDate(e.to, { hour: 'numeric', minute: '2-digit', timeZone: e.tz || TZ }, true);
      if (!/[AP]M$/i.test(fromTime))
        // Our locale does not use AM / PM
        return fromTime + ' – ' + toTime;
      if (fromTime.slice(-2) == toTime.slice(-2))
        // These times share the same suffix
        return fromTime.replace(/\s+[AP]M$/, '') + ' – ' + toTime;
      return fromTime + ' – ' + toTime;
    }
  ],

  location: [
    [ 'location' ],
    e => e.location
  ],
  address: [
    [ 'address' ],
    e => e.address
  ],
  address_single_line: [
    [ 'address' ],
    e => e.address && e.address.split('\n').filter(x => /\S/.test(x)).join(', ')
  ]
};


function getChunks(format) {
  return Array.from(format.matchAll(/[^{]+|{[^}]+}/g)).map(x => x[0]);
}


function formatTextEventLink(event, format) {
  let output = '';
  let hidden = 0;
  for (const c of getChunks(format)) {
    if (c == '{/if}') {
      if (hidden)
        hidden--;
      continue;
    }
    if (c.startsWith('{#if')) {
      const match = c.match(/{#if\s+(\S+)\s*}/);
      if (hidden || !match || !FORMATTERS[ match[1] ] || !FORMATTERS[ match[1] ][1](event))
        hidden++;
      continue;
    }
    const match = c.match(/^{\s*(\S+)\s*}$/);
    if (match && FORMATTERS[ match[1] ])
      output += FORMATTERS[ match[1] ][1](event) || '';
    else
      output += c;
  }
  return output;
}


function getEventFields(format) {
  const fields = new Set();
  for (const c of getChunks(format)) {
    const match = c.match(/{(#if\s+)?([^\/]\S+)\s*}/);
    if (match)
      for (const field of FORMATTERS[ match[2] ][0])
        fields.add(field);
  }
  return fields;
}


function updateEventLinks(order, updatedEvent) {
  let didUpdate = false;
  const prev = order.event || {};
  for (const page of order.pages) {
    for (const blob of page.blobs) {
      if ((blob.type == 'TEXT') && blob.text.event_link && !blob.text.event_link.disabled) {
        const formatted = formatTextEventLink(updatedEvent, blob.text.event_link.format);
        if (blob.text.text != formatted) {
          // Only update this blob if the formatted text actually changed
          blob.text.text = formatted;
          didUpdate = true;
        }
      }
    }
  }
  return didUpdate;
}


export { FORMATTERS, formatTextEventLink, getEventFields, updateEventLinks };

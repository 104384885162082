<template>
  <v-app class="transparent" v-bind:class="{ dark: global.dark }">

<!-- $router.hydrated: {{$router.hydrated}} -->

    <!-- For global temporary notifications -->
    <snackbar v-if="global.notify" v-bind="global.notify.props" :value="!!global.notify" @input="global.notify = null">
      <span v-html="global.notify.msg" />
    </snackbar>

    <sign-in-dialog v-if="showSignIn" v-model="showSignIn" />

    <!-- Note that setting the height is necessary to function properly on iOS -->
    <v-navigation-drawer
      v-if="sections.header"
      v-no-scroll
      style="height: 100%;"
      app right disable-resize-watcher mobile-breakpoint="999999" color="grey lighten-5"
      v-model="showNavMenu"
    >
      <v-list class="py-0">
        <v-list-item class="px-4 py-2 align-center" exact :to="{ name: 'start' }">
          <v-icon color="ec-dark-blue" v-html="mdiHome" />
          <v-spacer />
          <img class="ml-2 d-block" width="80" src="/ellacard-logo.png" alt="Ellacard" title="Ellacard" />
        </v-list-item>
        <v-divider />
        <v-list-item :to="{ name: 'account' }">
          <v-icon color="ec-dark-blue" v-html="isSignedIn ? mdiAccountCheck : mdiAccount" />
          <v-list-item-content class="ml-3">
            <v-list-item-title class="ec-dark-blue--text text-body-2">Account</v-list-item-title>
            <v-list-item-subtitle class="caption" v-html="isSignedIn ? global.profile.email : 'Not signed in'" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="showPending = (showPending == 'side') ? null : 'side'">
          <div class="flex-shrink-0">
            <v-progress-circular v-if="loadPendingOrdersStatus.pending" indeterminate color="ec-dark-blue" size="24" width="2" />
            <v-icon v-else color="ec-dark-blue" v-html="mdiCart" />
          </div>
          <v-list-item-title class="ml-3 text-body-2 ec-dark-blue--text">
            Saved projects
          </v-list-item-title>
          <v-list-item-action>
            <v-icon v-html="(showPending == 'side') ? mdiMenuUp : mdiMenuDown" />
          </v-list-item-action>
        </v-list-item>
        <v-expand-transition>
          <div
            v-show="(showPending == 'side') && !loadPendingOrdersStatus.pending"
            class="overflow-auto"
            style="max-height: 372px;"
          >
            <cart-list-item
              v-for="[ orderId, order ] in pendingOrders" :key="'side-' + orderId"
              :order-id="orderId"
              :order="order"
              :to="{ name: 'project', params: { orderId, routeOnClose: $routeAssign({}) } }"
            />
            <div v-if="!pendingOrders.length" class="pl-13 py-3 text--label-active font-italic">No saved projects</div>
          </div>
        </v-expand-transition>
        <v-divider />
        <component
          v-for="component, i in NAV_MENUS" :key="'list-' + i"
          ref="menu"
          :is="component"
          :disabled="menu != -1 && menu != i"
          @spotlight="v => menu = v ? i : menu == -1 || menu == i ? -1 : menu"
        />
        <v-divider />
        <v-list-item
          v-for="[ name, title, icon ] in [
            [ 'catalog-categories', 'Categories', mdiExpandAll ],
            [ 'catalog', 'Cards', mdiCards ],
            [ 'catalog-video', 'Videos', mdiVideoVintage ],
            [ 'featured', 'Featured cards', mdiTrophy ],
            [ 'batch-order', 'Batch order', mdiCalendarMultiselect ],
            [ 'design', 'Design program', mdiImageSizeSelectLarge ],
            [ 'pricing', 'Pricing', mdiCurrencyUsd ],
            [ 'faq', 'Faq', mdiFrequentlyAskedQuestions ],
            [ 'blog', 'Blog', mdiFormatFloatLeft ],
            [ 'site-map', 'More', mdiDotsHorizontal ]
          ]"
          :key="name"
          class="ec-dark-blue--text"
          :to="{ name }"
        >
          <v-icon color="ec-dark-blue" v-html="icon" />
          <v-list-item-title class="ml-3 text-body-2" v-html="title" />
        </v-list-item>
        <v-divider />
        <v-list-item href="mailto:contact@ellacard.com">
          <v-icon color="ec-dark-blue" v-html="mdiEmail" />
          <v-list-item-content class="ml-3">
            <v-list-item-title class="ec-dark-blue--text text-body-2">Contact us</v-list-item-title>
            <v-list-item-subtitle>contact@ellacard.com</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider />

      </v-list>

      <template v-if="global.profile?.admin">
        <div class="overline pt-2 px-4 mb-n2">Admin</div>
        <v-list dense>
          <v-list-item
            v-for="name in $router.options.routes.find(x => x.name == 'admin').children.map(x => x.name.substr(6))"
            :key="name"
            :to="{ name: 'admin-' + name }"
          >
            <v-list-item-title class="font-italic" v-html="name" />
          </v-list-item>
        </v-list>
      </template>

    </v-navigation-drawer>

    <v-main class="relative">
      <div v-if="sections.header" class="section-header relative" :class="$router.currentRouteMeta.$class">

        <div class="nav-btns pa-3 d-flex justify-end">
          <router-link class="mb-n3" :to="{ name: 'start' }">
            <img class="d-block" height="56" src="/ellacard-logo.png" alt="Ellacard" title="Ellacard" />
          </router-link>

          <v-spacer />

          <!-- TODO: experiment with putting a search field in here -->
          <!--
          <v-text-field
            class="mt-0 white"
            style="pointer-events: auto;"
            dense hide-details outlined rounded placeholder="Search"
            :append-icon="mdiMagnify"
          />
          -->
          

          <v-btn
            class="px-2 px-sm-3 hidden-xs-only"
            style="pointer-events: auto; text-transform: none;"
            active-class="before-opacity-0" text color="ec-dark-blue" aria-label="Home"
            :to="{ name: 'start' }"
          >
            <v-icon v-html="mdiHome" />
            <span class="btn-tooltip bottom">Home</span>
          </v-btn>
          <v-btn
            class="px-2 px-sm-3"
            style="pointer-events: auto; text-transform: none;"
            active-class="before-opacity-0" text color="ec-dark-blue" aria-label="All cards and invitations"
            :to="{ name: 'catalog' }"
          >
            <v-icon v-html="mdiCards" />
            <span class="btn-tooltip bottom">All cards and invitations</span>
          </v-btn>

          <v-menu v-if="isSignedIn" eager bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs" v-on="on"
                class="px-2 pl-sm-3"
                text aria-label="Account" color="ec-dark-blue"
                :input-value="$route.matched[0]?.name == 'account'"
              >
                <v-icon v-html="mdiAccountCheck" />
                <div class="mx-1 hidden-xs-only" style="max-width: 30vw; overflow: hidden; text-transform: none;">
                  {{global.profile.email}}
                </div>
                <v-icon class="mx-n1" v-html="mdiMenuDown" />
                <span class="btn-tooltip bottom">Account</span>
              </v-btn>
            </template>
            <v-list class="py-0 grey lighten-5">
              <v-list-item exact :to="{ name: 'account' }">
                <v-icon color="ec-dark-blue" v-html="mdiAccount" />
                <v-list-item-content class="ml-3">
                  <v-list-item-title class="ec-dark-blue--text text-body-2">Account</v-list-item-title>
                  <v-list-item-subtitle v-if="$vuetify.breakpoint.xsOnly" v-text="global.profile.email" />
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-for="[ title, name ] in [
                  [ 'Cards you ordered', 'account-orders' ],
                  [ 'Cards you signed', 'account-signed' ],
                  [ 'Cards you received', 'account-received' ],
                  [ 'Ellacard contacts', 'account-contacts' ]
                ]"
                :key="name"
                :to="{ name }"
                class="pl-13"
              >
                <v-list-item-title class="ec-dark-blue--text text-body-2" v-html="title" />
              </v-list-item>
              <v-divider />
              <v-list-item :to="{ name: 'account-design' }">
                <v-icon color="ec-dark-blue" v-html="mdiImageSizeSelectLarge" />
                <v-list-item-title class="ml-3 ec-dark-blue--text text-body-2">Design portal</v-list-item-title>
              </v-list-item>
              <v-divider />
              <v-list-item :to="{ name: 'account-org' }">
                <v-icon color="ec-dark-blue" v-html="mdiDomain" />
                <v-list-item-title class="ml-3 ec-dark-blue--text text-body-2">Business portal</v-list-item-title>
              </v-list-item>
              <v-divider />
              <v-list-item @click="global.jwt = null">
                <v-icon color="ec-dark-blue" v-html="mdiLogout" />
                <v-list-item-title class="ml-3 ec-dark-blue--text text-body-2">Sign out</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn v-else text aria-label="Sign in" color="ec-dark-blue" @click="showSignIn = true">
            <v-icon v-html="mdiAccount" />
            <div class="ml-1 hidden-xs-only" style="text-transform: none;">Sign in</div>
            <span class="btn-tooltip bottom">Sign in</span>
          </v-btn>

          <v-menu
            v-no-scroll
            bottom offset-y close-on-content-click min-width="300" content-class="grey lighten-5"
            :value="showPending == 'top'"
            @input="x => showPending = x ? 'top' : null"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs" v-on="on"
                class="px-2 pl-sm-3"
                text aria-label="Saved projects" color="ec-dark-blue"
                :loading="(showPending == 'top') && loadPendingOrdersStatus.pending"
              >
                <v-icon v-html="mdiCart" />
                <v-icon class="mx-n1" v-html="mdiMenuDown" />
                <span class="btn-tooltip bottom">Saved projects</span>
              </v-btn>
            </template>
            <v-list v-show="!loadPendingOrdersStatus.pending" class="py-0 grey lighten-5">
              <div class="text--label-active pa-2">Saved projects</div>
              <cart-list-item
                v-for="[ orderId, order ] in pendingOrders" :key="orderId"
                class="pl-0"
                :order-id="orderId"
                :order="order"
                :to="{ name: 'project', params: { orderId, routeOnClose: $routeAssign({}) } }"
              />
              <div v-if="!pendingOrders.length" class="pa-3 text--label-active font-italic">No saved projects</div>
            </v-list>
          </v-menu>

          <v-btn
            class="px-2 px-sm-3"
            text aria-label="Navigation menu" color="ec-dark-blue"
            :input-value="showNavMenu"
            @click="showNavMenu = !showNavMenu"
          >
            <v-icon v-html="mdiMenu" />
          </v-btn>
        </div>

        <div class="nav-menu-btns hidden-xs-only mx-auto px-3 pb-3 d-sm-flex flex-wrap justify-center">
          <component
            v-for="component, i in NAV_MENUS" :key="'head-' + i"
            :is="component"
            header
            :disabled="!showNavMenu && menu != -1 && menu != i"
            @spotlight="v => menu = v ? i : menu == -1 || menu == i ? -1 : menu"
          />
        </div>
      </div>

      <router-view />
    </v-main>

    <!-- Note that we wait to attach the footer (if needed) until after the route has mounted to avoid CLS -->
    <v-footer v-if="sections.footer" dark tile>
      <div class="flex-grow-1 overline">
        <div class="my-2 float-right">&copy; 2024</div>
        <div class="my-2 white--text">Ellacard, LLC</div>
        <div class="mx-n2 d-flex flex-wrap">
          <router-link class="ma-2 white--text" to="/start">Home</router-link>
          <router-link class="ma-2 white--text" to="/faq">FAQ</router-link>
          <router-link class="ma-2 white--text" to="/about">About</router-link>
          <router-link class="ma-2 white--text" to="/privacy-policy">Privacy Policy</router-link>
          <router-link class="ma-2 white--text" to="/terms-and-conditions">Terms and Conditions</router-link>
          <router-link class="ma-2 white--text" to="/site-map">Site map</router-link>
          <a class="ma-2 white--text" href="mailto:contact@ellacard.com">contact@ellacard.com</a>
        </div>
        <div class="mx-n2 d-flex flex-wrap">
          <v-btn
            v-for="[ icon, title, url ] in SOCIALS" :key="title"
            class="ma-2" icon target="_blank"
            :aria-label="title"
            :href="url"
          >
            <v-icon v-html="icon" />
          </v-btn>
        </div>
      </div>
    </v-footer>

  </v-app>
</template>


<style scoped>
.nav-btns .v-btn {
  min-width: unset;
}

.nav-menu-btns {
  max-width: 600px;
}
@media (min-width: 960px) {
  .nav-menu-btns {
    max-width: unset;
  }
}

.before-opacity-0::before {
  opacity: 0;
}

.v-application {
  background-color: transparent !important;
}

.section-header {
  background: linear-gradient(#EDF6FC, #FFF);
  filter: drop-shadow(0 0 3px #FFF8);
}
.section-header.fade-transparent {
  background: linear-gradient(#EDF6FC, #FFF0);
}

footer a {
  white-space: nowrap;
}
</style>


<script>
// Just putting this here - there is no way around it, you will not be able to load previews in SMS texts for iOS users:
// https://stackoverflow.com/questions/50616536/remove-need-to-press-tap-to-load-preview-for-iphone-opengraph-sms-message

// Here's an article for detecting platform:
// https://stackoverflow.com/questions/38241480/detect-macos-ios-windows-android-and-linux-os-with-js

import CartListItem from '@/components/CartListItem.vue';
import NavMenuBusiness from '@/components/NavMenuBusiness.vue';
import NavMenuGroupGiftCards from '@/components/NavMenuGroupGiftCards.vue';
import NavMenuGroupVideos from '@/components/NavMenuGroupVideos.vue';
import NavMenuInvitations from '@/components/NavMenuInvitations.vue';
import NavMenuOccasions from '@/components/NavMenuOccasions.vue';
import NavMenuStart from '@/components/NavMenuStart.vue';
import SignInDialog from '@/components/SignInDialog.vue';
import Snackbar from '@/components/Snackbar.vue';
import jwtDecode from 'jwt-decode';
import loadCategories from '@/mixins/load-categories.js';
import pendingOrders from '@/mixins/pending-orders.js';
import { COUNTRY_CODE } from '@/utils/intl.js';
import store from '@/utils/local-storage.js';
import iconPathX from 'raw-loader!@/assets/icon-path-x.txt';
import { mdiClose, mdiImageSizeSelectLarge, mdiCards, mdiCart, mdiAccount, mdiAccountCheck, mdiHome, mdiFacebook, mdiInstagram, mdiLinkedin, mdiPinterest, mdiTrophy, mdiFormatFloatLeft, mdiMenu, mdiEmail, mdiFrequentlyAskedQuestions, mdiCloud, mdiVideoVintage, mdiDotsHorizontal, mdiDomain, mdiMenuDown, mdiMenuUp, mdiWrench, mdiOpenInNew, mdiCurrencyUsd, mdiLogout, mdiCalendarMultiselect, mdiExpandAll } from '@mdi/js';


const SOCIALS = [
  [ mdiFacebook, 'Facebook', 'https://www.facebook.com/ellacard' ],
  [ mdiInstagram, 'Instagram', 'https://instagram.com/ellacard_co' ],
  [ mdiLinkedin, 'LinkedIn', 'https://www.linkedin.com/company/ellacard' ],
  [ mdiPinterest, 'Pinterest', 'https://www.pinterest.com/ellacard_co' ],
  [ iconPathX, 'X', 'https://x.com/ellacard_co' ]
];


// Routes for which the cookie warning should not show
// const NO_COOKIES = [
//   null,
//   '_capture',
//   '_print',
//   'sign-in',
//   'card-open',
//   'card-sign',
//   'video-project',
//   'cookies',
//   'unsubscribe',
//   'confirm-unsubscribe'
// ];

const NAV_MENUS = [
  NavMenuStart,
  NavMenuOccasions,
  NavMenuInvitations,
  NavMenuGroupGiftCards,
  NavMenuGroupVideos,
  NavMenuBusiness
];

export default {
  name: 'app',

  mixins: [ loadCategories, pendingOrders ],

  components: {
    CartListItem,
    SignInDialog,
    Snackbar
  },

  data() {
    return {
      menu: -1, // The index of the spotlighted menu in NAV_MENUS
      showNavMenu: false,
      showSignIn: false,
      showPending: null
    };
  },

  computed: {
    sections() {
      if (!this.$route.matched.length)
        return {};
      const output = { header: true, footer: this.$router.hydrated };
      for (const section of (this.$router.currentRouteMeta.$hide || []))
        output[section] = false;
      return output;
    }
  },

  created() {
    Object.assign(this, {
      NAV_MENUS,
      SOCIALS,
      mdiClose,
      mdiImageSizeSelectLarge,
      mdiCards,
      mdiCart,
      mdiDomain,
      mdiCurrencyUsd,
      mdiAccount,
      mdiAccountCheck,
      mdiHome,
      mdiMenu,
      mdiEmail,
      mdiCloud,
      mdiDotsHorizontal,
      mdiMenuDown,
      mdiMenuUp,
      mdiOpenInNew,
      mdiVideoVintage,
      mdiFrequentlyAskedQuestions,
      mdiFormatFloatLeft,
      mdiTrophy,
      mdiLogout,
      mdiCalendarMultiselect,
      mdiExpandAll
    });
  },

  mounted() {
    loadDeferredScripts();
  },

  methods: {
    signOut() {
      this.global.jwt = null;
    },

    trySetLocalStorage(key, value) {
      if (store.isAvailable() && this.global.cookies && this.global.cookies.strict)
        store.set(key, value);
    }
  },

  watch: {
    showNavMenu(v) {
      if (!v)
        this.menu = -1;
    },

    globalUserId(v) {
      const p = this.global.profile;
      $event.log('sign-in', v ? (p.anon ? 'anon' : 'real') : null);
      if (v && !p.anon) {
        gtag('set', { user_id: v });
        gtag('set', 'user_data', { email: p.email });
      }
    },

    'global.jwt': {
      immediate: true,
      async handler(v, prev) {
        if (v != prev)
          this.trySetLocalStorage('jwt', v);

        let profile = undefined;
        if (v) {
          try {
            profile = jwtDecode(v);
          } catch (e) {
            console.error('Error decoding JWT', e);
          }
        }
        this.global.profile = profile;
      }
    },

    'global.cookies': {
      immediate: true,
      handler(to, from) {
        if (!store.isAvailable())
          return;

        this.trySetLocalStorage('cookies', to);

        const scope = to || { strict: true };

        // Ignore consent settings for now
        // gtag('consent', 'update', { 'analytics_storage': scope.performance ? 'granted' : 'denied' });
        // gtag('consent', 'update', { 'ad_storage': scope.targeting ? 'granted' : 'denied' });
        // fbq('consent', scope.targeting ? 'grant' : 'revoke');

        if ((!from || !from.strict) && to && to.strict)
          // We've just been given permission to write to localStorage
          for (const key of store.PERSIST)
            store.set(key, this.global[key]);

        else if ((!to || !to.strict) && from && from.strict)
          // We've just lost permission to write to localStorage (delete everything)
          store.clear();
      }
    },

    'global.videoDeviceId'(v) {
      this.trySetLocalStorage('videoDeviceId', v);
    },

    'global.audioDeviceId'(v) {
      this.trySetLocalStorage('audioDeviceId', v);
    },

    'global.showThemePresets'(v) {
      this.trySetLocalStorage('showThemePresets', v);
    },

    'global.showHelp'(v) {
      this.trySetLocalStorage('showHelp', v);
    },

    '$vuetify.breakpoint.xsOnly': {
      immediate: true,
      handler(v) {
        if (v)
          this.global.showHelp = false;
      }
    },

    showPending(v) {
      if (v)
        this.loadPendingOrders();
    },

    async $route() {
      this.showPending = false;
      if (this.$vuetify.breakpoint.mdAndDown)
        this.showNavMenu = false;
    }
  }
};
</script>

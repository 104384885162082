const RESERVED_TITLES = new Set([ 'Name', 'Can you make it?', 'Adults', 'Children', 'Attending', 'Attendees', 'Total number of attendees' ]);

function getFixedInputs(n_rsvp, separate_counts, request_names) {
  const inputs = [
    // {
    //   // key: 'RSVP_AS',
    //   type: 'TEXT_SHORT',
    //   title: 'Name',
    //   lock: [ 'move', 'title', 'subtitle', 'required', 'placeholder' ],
    //   root: true,
    //   required: true,
    //   placeholder: 'Name'
    // },
    {
      // key: 'ATTENDING',
      type: 'RADIO',
      title: 'Can you make it?',
      lock: [ 'move', 'title', 'subtitle', 'required', 'choices' ],
      root: true,
      required: true,
      row: true,
      choices: [ 'Yes', 'No' ]
    }
  ];
  if (n_rsvp > 1) {
    if (request_names)
      inputs.push({
        // key: 'ATTENDEE_NAMES',
        type: 'ATTENDEE_NAMES',
        title: 'Attendees',
        subtitle: 'List the names of those in your party (including yourself)' + (separate_counts ? ' and please indicate any children' : ''),
        lock: [ 'move', 'title', 'subtitle', 'required', 'delete' ],
        public: true,
        conditional: true,
        required: true,
        separate_counts,
        min: 1,
        max: n_rsvp
      });
    else if (separate_counts)
      inputs.push({
        // key: 'ATTENDEE_SEPARATE_COUNTS',
        type: 'ATTENDEE_SEPARATE_COUNTS',
        title: 'Total number of attendees',
        subtitle: 'Including yourself',
        lock: [ 'move', 'title', 'subtitle', 'conditional', 'required', 'delete', 'range' ],
        public: true,
        conditional: true,
        required: true,
        min: 1,
        max: n_rsvp
      });
    else
      inputs.push({
        // key: 'ATTENDEE_N',
        type: 'INT',
        title: 'Total number of attendees',
        subtitle: 'Including yourself',
        lock: [ 'move', 'title', 'subtitle', 'conditional', 'required', 'delete', 'range' ],
        public: true,
        conditional: true,
        required: true,
        min: 1,
        max: n_rsvp
      });
  }
  return inputs;
}


function buildFormInputs(form) {
  // The form must always start with these fixed inputs (the settings of which are configurable however)
  const fixed = getFixedInputs(form.n_rsvp, form.separate_counts, form.request_names);

  // DEBUG: the RSVP_AS type has been replaced by the TEXT_SHORT type
  // for (const input of form.inputs) {
  //   if (input.type == 'RSVP_AS') {
  //     input.type = 'TEXT_SHORT';
  //     input.placeholder = 'Name';
  //   }
  // }

  const fixedByTitle = {};
  for (const x of fixed)
    fixedByTitle[x.title] = x;

  form.inputs = form.inputs.filter(x => fixedByTitle[x.title] || !RESERVED_TITLES.has(x.title));
  const found = form.inputs.find(x => x.title == 'Total number of attendees');
  if (found)
    found.type = form.separate_counts ? 'ATTENDEE_SEPARATE_COUNTS' : 'INT';
  for (const x of form.inputs)
    if (fixedByTitle[x.title])
      Object.assign(fixedByTitle[x.title], x);

  const titles = new Set();
  return [ ...fixed, ...form.inputs.filter(x => !x.key) ].filter(x => !titles.has(x.title) && titles.add(x.title));
}


function patchResponse(form, response) {
  // Return a response that has the correct structure for the provided 'form' options for use with the form-rsvp
  // component
  const patched = _.cloneDeep(response);
  const { request_names, n_rsvp, separate_counts } = form;
  const updates = {};
  if (request_names) {
    if (n_rsvp > 1) {
      if (!Array.isArray(patched['Attendees']))
        // The 'Attendees' field will be showing, and it's not currently in the right format (possibly was used to
        // answer another questions that previously had the name "Attendees" or more likely just doesn't exist
        // yet)
        patched['Attendees'] = [ { name: '' } ];
      for (const x of patched['Attendees']) {
        if (!x.hasOwnProperty('name'))
          x.name = '';
        if (separate_counts && !x.hasOwnProperty('type'))
          x.type = 'adult';
      }
    }
    delete patched['Total number of attendees'];
  } else {
    if (separate_counts && !_.isObject(patched['Total number of attendees'])) {
      patched['Total number of attendees'] = { adult: 0, child: 0 };
    } else if (!separate_counts && _.isObject(patched['Total number of attendees'])) {
      const { adult, child } = patched['Total number of attendees'];
      patched['Total number of attendees'] = (adult || 0) + (child || 0);
    }
    if (!patched.hasOwnProperty('Total number of attendees') && patched.hasOwnProperty('Attendees'))
      patched['Total number of attendees'] = patched['Attendees'].length;
  }
  return patched;
}


export { getFixedInputs, buildFormInputs, patchResponse };
